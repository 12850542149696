import axios from 'axios';
// -------------------------------------
const BASE_URL = process.env.REACT_APP_BASE_URL;
const rootUrl = 'http://127.0.0.1:8008/api/auth/';
const logoutUrl = `${rootUrl}logout`;
const accessToken = JSON.parse(localStorage.getItem('naborhood'));

// const { token } = accessToken && accessToken.token;

export const userLogout = async ({ accessToken }) => {
  const token = accessToken;
  try {
    await axios
      .get(logoutUrl, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const logoutMsg = response.data;
        const { message } = logoutMsg;
        localStorage.removeItem('naborhood');
        console.log(message);
      })
      .catch((e) => {
        console.log(e.message);
      });
  } catch (error) {
    console.log(error);
  }
};
