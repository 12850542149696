import * as React from 'react';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import stockFill from '@iconify/icons-eva/archive-fill';
import { Link as RouterLink } from 'react-router-dom';
import BatteryIcon from '@mui/icons-material/Battery20';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import InputIcon from '@mui/icons-material/Input';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
// material
import { Card, Grid, Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import InventoryCard from '../components/inventory/InventoryCard';
import CostCard from '../components/inventory/CostCard';
import {
  InventoryTable,
  InventorySummaryTable,
  AddItemForm,
  AddStockForm
} from '../components/inventory';
import CustomizedDialogs from '../components/CustomizedDialogs';
//

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function Inventory() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dateValue, setDateValue] = useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };
  return (
    <Page title="Inventory | Naborhood Auto">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Inventory Management
          </Typography>
        </Stack>
        <Card>
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-end"
            spacing={3}
            sx={{ px: 2.5, pt: 3, mb: 3, mr: 3 }}
          >
            <CustomizedDialogs
              title="Add New Item"
              linkName="Add New Item"
              variant="text"
              icon={<Icon icon={plusFill} />}
            >
              <AddItemForm />
            </CustomizedDialogs>

            <CustomizedDialogs
              title="Add Stock"
              linkName="Add Stock"
              variant="text"
              icon={<Icon icon={stockFill} />}
            >
              <AddStockForm />
            </CustomizedDialogs>
          </Stack>
          {/* inventory card */}
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            // spacing={3}
            sx={{ px: 0, pt: 3, mb: 3, mr: 0 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <InventoryCard
                  stockTitle="Low Stock"
                  stockLevel="0"
                  avatarColor="text.warning"
                  avatarIcon={<BatteryIcon />}
                  sx={{ px: 0, pt: 3, mb: 3, mr: 0 }}
                />
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <InventoryCard
                  stockTitle="Out of Stock"
                  stockLevel="8"
                  avatarColor="text.danger"
                  avatarIcon={<BatteryAlertIcon />}
                />
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid item xs={12} sm={12} md={3} lg={3} sx={{}}>
                <Stack
                  display="flex"
                  spacing={3}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="caption"
                    sx={{
                      color: 'text.disabled',
                      display: 'block',
                      fontSize: 16,
                      mb: 1
                    }}
                  >
                    This Month
                  </Typography>
                  <CostCard
                    stockTitle="Stock Received"
                    amount="100000"
                    avatarColor="text.success"
                    avatarIcon={<InputIcon />}
                  />
                  <CostCard
                    stockTitle="Stock Sold"
                    amount="400000"
                    avatarColor="text.warning"
                    avatarIcon={<LogoutIcon />}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Card>
        <Box sx={{ width: '100%', typography: 'body1', my: 5, py: 4 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="Tabs">
                <Tab label="Item Inventory" value="1" />
                <Tab label="Item Summary" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <Stack>
                <Card sx={{ borderRadius: 0 }}>
                  <InventoryTable />
                </Card>
              </Stack>
            </TabPanel>
            {/* second tab */}
            <TabPanel value="2" sx={{ px: 0 }}>
              {/* <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="flex-end"> */}
              <Stack>
                <Card sx={{ borderRadius: 0 }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      px: 4,
                      pt: 3,
                      pb: 2,
                      mt: 2,
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="From"
                        inputFormat="MM/dd/yyyy"
                        value={dateValue}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <KeyboardTabIcon />
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        value={dateValue}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <InventorySummaryTable />
                </Card>
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
