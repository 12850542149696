// use selector
import { useSelector } from 'react-redux';
// routes
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// import Router from './routes';
import routes from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import login from './store/store';
// ----------------------------------------------------------------------

export default function App() {
  // const token = useSelector((state) => state.login.token);
  const token = localStorage.getItem('naborhood');

  const routing = useRoutes(routes(token));
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      {/* <Router /> */}
      {routing}
    </ThemeConfig>
  );
}
