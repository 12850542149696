import faker from 'faker';
// utils
import { mockImgCover } from '../utils/mockImages';

// ----------------------------------------------------------------------

const POST_TITLES = [
  'Lube oil & filter change',
  'Lube oil & filter change Services',
  'Engine Maintenance Services',
  'Legs Repair Services',
  'Tires & Wheel Services',
  'Wiring & Battery Services'
];

const posts = [...Array(5)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: mockImgCover(index + 1),
  title: POST_TITLES[index + 1],
  createdAt: faker.date.past(),
  comment: faker.datatype.number(),
  // view: faker.datatype.number(),
  // share: faker.datatype.number(),
  // favorite: faker.datatype.number(),
  author: {
    name: faker.name.findName(),
    avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`
  }
}));

export default posts;
