import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  getService: null
};
const getServiceSlice = createSlice({
  name: 'getService',
  initialState,

  reducers: {
    getService: (state = initialState, action) => {
      state.getService = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
const { reducer, actions } = getServiceSlice;

export const { getService } = actions;

export default reducer;
