import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import UploadIcon from '@mui/icons-material/Upload';
// material
import { TextField, IconButton, Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

export default function FileUpload() {
  //   const handleFileUpload = () => {};
  const [value, setValue] = useState('');

  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };

  return (
    <TextField
      //   id={`sim-script-${sim.uuid}`}
      label="Upload Cover Image"
      //   inputRef={scriptInputRef} // To focus on the field after clicking icon
      value={value}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <>
            <Tooltip title="Upload Service Group Cover Image" arrow>
              <IconButton aria-label="upload" component="label">
                <UploadIcon />
                <input hidden type="file" onChange={handleChange} />
              </IconButton>
            </Tooltip>
          </>
        )
      }}
    />
  );
}
