import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.findName(),
  phone: faker.phone.phoneNumber(),
  verified: faker.datatype.boolean(),
  createdAt: faker.date.past(),
  vehicle: faker.vehicle.vehicle(),
  status: sample(['active', 'inactive']),
  role: sample(['user', 'admin']),
  stockStatus: sample(['In stock', 'low stock']),
  serviceNo: faker.datatype.number(),
  email: faker.internet.email(),
  attended: faker.datatype.number({ min: 0, max: 4, precision: 1.0 }),
  quantity: faker.datatype.number({ min: 1, max: 100, precision: 1.0 }),
  restock: faker.datatype.number({ min: 1, max: 100, precision: 1.0 }),
  reorder: faker.datatype.number({ min: 0, max: 4, precision: 1.0 }),
  price: faker.datatype.number({ min: 1000, max: 20000, precision: 0.01 }),
  total: faker.datatype.number({ min: 5000, max: 100000, precision: 0.01 }),
  income: faker.datatype.number({ min: 10000, max: 100000, precision: 0.01 }),
  service: sample([
    'Engine oil and filter change',
    'Fuel filter change',
    'Transition fluid change',
    'Engine diagnostic scanning & performance',
    'Spark plugs & coil change',
    'Engine tune up',
    'Cooling system repairs (radiator & fans)',
    'Fuel system services (fuel pump, nozzles)',
    'Top cylinder services',
    'Complete engine replacement',
    'Brake pad & lining, caliper change',
    'Shock absorber & stabilizer linkage change',
    'Bushing, tie rod end & socket change',
    'Axle, ball joints, drive shaft, hub and bearing change',
    'Wheel alignment & balancing',
    'Tire change',
    'Electrical wiring diagnostic & performance',
    'Battery checks & change',
    'Hybrids & electric cars'
  ]),
  serviceGroup: sample([
    'Lube oil & filter change',
    'Lube oil & filter change Services',
    'Engine Maintenance Services',
    'Legs Repair Services',
    'Tires & Wheel Services',
    'Wiring & Battery Services'
  ]),

  serviceNote: sample([
    'Engine oil and filter change',
    'Fuel filter change',
    'Transition fluid change',
    'Engine diagnostic scanning & performance',
    'Spark plugs & coil change',
    'Engine tune up',
    'Cooling system repairs (radiator & fans)',
    'Fuel system services (fuel pump, nozzles)',
    'Top cylinder services',
    'Complete engine replacement',
    'Brake pad & lining, caliper change',
    'Shock absorber & stabilizer linkage change',
    'Bushing, tie rod end & socket change',
    'Axle, ball joints, drive shaft, hub and bearing change',
    'Wheel alignment & balancing',
    'Tire change',
    'Electrical wiring diagnostic & performance',
    'Battery checks & change',
    'Hybrids & electric cars'
  ]),
  item: sample([
    'Engine oil',
    'Spark plugs',
    'Filter',
    'Fuel Pump',
    'Tyres',
    'Piston',
    'Head lamp',
    'Wiper',
    'Paint',
    'Jack'
  ])
}));

export default users;
