import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from './login/loginSlice';
import userReducer from './user/userSlice';
import serviceReducer from './service/serviceSlice';
import getServiceReducer from './service/getServiceSlice';
import serviceGroupReducer from './service/serviceGroupSlice';

const rootReducer = combineReducers({
  // reducer: {
  login: loginReducer,
  user: userReducer,
  service: serviceReducer,
  getService: getServiceReducer,
  serviceGroup: serviceGroupReducer
  // }
});

const persistConfig = {
  key: 'main-root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);

export const persistor = persistStore(store);
