import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// selector
import { useSelector } from 'react-redux';
// components
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
//
import POSTS from '../_mocks_/blog';
import { ServiceGroupAddForm } from '../components/services';
import CustomizedDialogs from '../components/CustomizedDialogs';
// mock images
import { mockImgCover } from '../utils/mockImages';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function Service() {
  // user data
  const serviceGroups = useSelector((state) => state.serviceGroup.serviceGroup);

  const serviceGroup = [...Array(serviceGroups.data.service_group_detail.length)].map(
    (_, index) => ({
      id: serviceGroups.data.service_group_detail[index].id,
      cover: mockImgCover(index + 1),
      title: serviceGroups.data.service_group_detail[index].service_group_name,
      createdAt: serviceGroups.data.service_group_detail[index].updated_at,
      author: {
        name: serviceGroups.data.service_group_detail[index].staff_name,
        avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`
      }
    })
  );

  return (
    <>
      <Page title="Dashboard: Service | Naborhood Auto">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Service Groups
            </Typography>

            <CustomizedDialogs
              title="Add Service Group"
              linkName="Add Service Group"
              variant="contained"
              icon={<Icon icon={plusFill} />}
            >
              <ServiceGroupAddForm />
            </CustomizedDialogs>
          </Stack>

          <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
            <BlogPostsSearch posts={POSTS} />
            <BlogPostsSort options={SORT_OPTIONS} />
          </Stack>

          <Grid container spacing={3}>
            {serviceGroup.map((post, index) => (
              <BlogPostCard
                key={post.id}
                post={post}
                index={index}
                title={post}
                to={`${post.id}`}
              />
            ))}
          </Grid>
        </Container>
      </Page>
    </>
  );
}
