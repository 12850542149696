import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  userToken: null,
  isLoggedIn: false
};
const loginSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    login: (state = initialState, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    register: (state = initialState, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    logout: (state = initialState) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
    }
  }
});

// Action creators are generated for each case reducer function
const { reducer, actions } = loginSlice;

export const { login, register, logout } = actions;

export default reducer;
