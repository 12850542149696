import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgProduct } from '../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  //   Lube oil & filter change
  // •	Engine oil and filter change
  // •	Fuel filter change
  // •	Transition fluid change

  // Engine Maintenance Services
  // •	Engine diagnostic scanning & performance
  // •	Spark plugs & coil change
  // •	Engine tune up
  // •	Cooling system repairs (radiator & fans)
  // •	Fuel system services (fuel pump, nozzles)
  // •	Top cylinder services
  // •	Complete engine replacement.

  // Legs Repair Services
  // •	Brake pad & lining, caliper change
  // •	Shock absorber & stabilizer linkage change
  // •	Bushing, tie rod end & socket change
  // •	Axle, ball joints, drive shaft, hub and bearing change

  // Tires & Wheel Services
  // •	Wheel alignment & balancing
  // •	Tire change

  // Wiring & Battery Services
  // •	Electrical wiring diagnostic & performance
  // •	Battery checks & change
  // •	Hybrids & electric cars.

  'Cooling system repairs (radiator & fans)',
  'Fuel system services (fuel pump, nozzles)',
  'Top cylinder services',
  'Complete engine replacement'
  // 'Nike ZoomX SuperRep Surge',
  // 'Zoom Freak 2',
  // 'Nike Air Max Zephyr',
  // 'Jordan Delta'
  // 'Air Jordan XXXV PF',
  // 'Nike Waffle Racer Crater',
  // 'Kyrie 7 EP Sisterhood',
  // 'Nike Air Zoom BB NXT',
  // 'Nike Air Force 1 07 LX',
  // 'Nike Air Force 1 Shadow SE',
  // 'Nike Air Zoom Tempo NEXT%',
  // 'Nike DBreak-Type',
  // 'Nike Air Max Up',
  // 'Nike Air Max 270 React ENG',
  // 'NikeCourt Royale',
  // 'Nike Air Zoom Pegasus 37 Premium',
  // 'Nike Air Zoom SuperRep',
  // 'NikeCourt Royale',
  // 'Nike React Art3mis',
  // 'Nike React Infinity Run Flyknit A.I.R. Chaz Bear'
];

const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

// ----------------------------------------------------------------------

const products = [...Array(4)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: mockImgProduct(setIndex),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 1000, max: 10000, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', ''])
  };
});

export default products;
