import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Card, Link, Grid, Typography, Stack } from '@mui/material';
import editFill from '@iconify/icons-eva/edit-fill';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import { ServiceEditForm } from '../../services';
import CustomizedDialogs from '../../CustomizedDialogs';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale } = product;

  return (
    // <Grid item xs={12} sm={6} md={4}>
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )} */}
        <ProductImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        {/* <Link to="#" color="inherit" underline="hover" component={RouterLink}> */}
        <Typography variant="subtitle2">{name}</Typography>
        {/* </Link> */}

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                textDecoration: 'line-through'
              }}
            >
              N
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>

          <CustomizedDialogs
            title="Edit Service"
            linkName="Edit"
            variant="contained"
            icon={<Icon icon={editFill} />}
          >
            <ServiceEditForm />
          </CustomizedDialogs>
        </Stack>
      </Stack>
    </Card>
    // </Grid>
  );
}
