import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import BatteryIcon from '@mui/icons-material/Battery20';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
// material
import { Box, Stack, Avatar, Typography } from '@mui/material';
// utils
import { fDate } from '../../utils/formatTime';
import { fCurrency, fNumber } from '../../utils/formatNumber';
//
import SvgIconStyle from '../SvgIconStyle';

// ----------------------------------------------------------------------

export default function InventoryCard({ stockLevel, stockTitle, avatarColor, avatarIcon }) {
  //   const { title, stockLow, stockOut } = post;

  //   const POST_INFO = [
  //     { number: stockLow, icon: messageCircleFill, number: stockOut, icon: messageCircleFill }
  //   ];

  return (
    <Stack
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mt: 0 }}
    >
      <Typography
        gutterBottom
        variant="caption"
        sx={{ color: 'text.disabled', display: 'block', fontSize: 20 }}
      >
        {stockTitle}
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ px: 2.5, pt: 0, mb: 0 }}
      >
        <Avatar sx={{ bgcolor: `${avatarColor}` }}>{avatarIcon}</Avatar>

        <Typography
          gutterBottom
          variant="caption"
          sx={{ color: 'text.secondary', display: 'block', fontSize: 110, mt: 0, pt: 0 }}
        >
          {stockLevel}
        </Typography>
      </Stack>
    </Stack>
  );
}
