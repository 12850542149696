import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import BatteryIcon from '@mui/icons-material/Battery20';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
// material
import { Box, Stack, Avatar, Typography } from '@mui/material';
// utils
import { fDate } from '../../utils/formatTime';
import { fCurrency, fNumber } from '../../utils/formatNumber';
//
import SvgIconStyle from '../SvgIconStyle';

// ----------------------------------------------------------------------

export default function CostCard({ stockTitle, avatarColor, avatarIcon, amount }) {
  //   const { title, stockLow, stockOut } = post;

  //   const POST_INFO = [
  //     { number: stockLow, icon: messageCircleFill, number: stockOut, icon: messageCircleFill }
  //   ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" spacing={2}>
      <Avatar sx={{ bgcolor: `${avatarColor}`, height: 40, width: 40 }} variant="rounded">
        {avatarIcon}
      </Avatar>
      <Stack display="flex" flexWrap="wrap">
        <Typography
          variant="caption"
          sx={{ color: 'text.disabled', display: 'block', fontSize: 12 }}
        >
          {stockTitle}
        </Typography>

        <Stack direction="row" flexWrap="wrap" spacing={1}>
          <Typography variant="subtitle1" sx={{ fontSize: 20, color: 'text.secondary' }}>
            <Typography
              component="span"
              variant="subtitle1"
              sx={{
                fontSize: 20,
                textDecoration: 'line-through'
              }}
            >
              N
            </Typography>
            &nbsp;
            {fCurrency(amount)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
