import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function ServiceQuantityForm() {
  const navigate = useNavigate();

  const [service, setService] = useState('');

  const handleChange = (event) => {
    setService(event.target.value);
  };

  const ServiceQuantitySchema = Yup.object().shape({
    service: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Item name is required'),
    quantity: Yup.string().min(0, 'quantity too small').required('service quantity is required')
  });

  const formik = useFormik({
    initialValues: {
      service: '',
      quantity: ''
    },
    validationSchema: ServiceQuantitySchema,
    onSubmit: () => {
      navigate('/dashboard/products/createServiceRequest', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ minWidth: 300 }}>
          <Stack direction="column" spacing={2}>
            <TextField
              labelId="item-label"
              id="service"
              value={service}
              disabled
              label="Selected Service"
              onChange={handleChange}
              {...getFieldProps('service')}
              error={Boolean(touched.service && errors.service)}
              helperText={touched.service && errors.service}
            />

            <TextField
              fullWidth
              type="number"
              label="Quantity"
              {...getFieldProps('quantity')}
              error={Boolean(touched.quantity && errors.quantity)}
              helperText={touched.quantity && errors.quantity}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Discard
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
