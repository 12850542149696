import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Avatar, Card, Button, Container, Link, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// components
import Page from '../components/Page';
import { UserAvatar, BiodataForm } from '../components/profile/bio-data';
import { VehicleAvatar, VehicleForm } from '../components/profile/vehicle';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function Profile() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Dashboard: Profile | Naborhood Auto">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="Tabs" variant="fullWidth">
                  <Tab label="Personal Info" value="1" />
                  <Tab label="Vehicle Info" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Stack
                  direction="row"
                  flexWrap="wrap-reverse"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 5, py: 0, mb: 0 }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Bio-Data
                  </Typography>
                  {/* <Button
                    variant="contained"
                    component={RouterLink}
                    to="#"
                    startIcon={<Icon icon={editFill} />}
                  >
                    Edit Bio-Data
                  </Button> */}
                </Stack>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 5, pt: 3, mb: 0 }}
                >
                  <UserAvatar name="Jaydon Frankie" />
                  <BiodataForm />
                </Stack>
              </TabPanel>

              {/* vehicle profile section */}

              <TabPanel value="2">
                <Stack
                  direction="row"
                  flexWrap="wrap-reverse"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 5, py: 0, mb: 0 }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Vehicle Details
                  </Typography>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to="#"
                    startIcon={<Icon icon={plusFill} />}
                  >
                    Add Vehicle
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  flexWrap="wrap-reverse"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 5, pt: 3, mb: 0 }}
                >
                  <VehicleForm />
                  <VehicleAvatar name="Mercedes Benz" />
                </Stack>
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
