/* eslint-disable camelcase */
// material
import { Grid, Container, Stack, Typography } from '@mui/material';
// useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
//
import { user } from '../store/user/userSlice';
import { services } from '../store/service/serviceSlice';
import { serviceGroup } from '../store/service/serviceGroupSlice';
// components
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppCurrentVisits,
  AppWebsiteVisits
} from '../components/_dashboard/app';
import Fabs from '../components/Fabs';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const loadedUser = useSelector((state) => state.login.user);
  const loadedServices = useSelector((state) => state.service.services);
  const loadedServiceGroup = useSelector((state) => state.serviceGroup.serviceGroup);

  const dispatch = useDispatch();
  const accessToken = JSON.parse(localStorage.getItem('naborhood'));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { token } = accessToken;

  const getAllDetails = async () => {
    await axios
      .get(`${BASE_URL}get-all-detail`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const userProfile = response.data;
        dispatch(user(userProfile));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const getServiceGroup = async () => {
    await axios
      .get(`${BASE_URL}get-service-group`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const serviceGroups = response.data;
        dispatch(serviceGroup(serviceGroups));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const getServices = async () => {
    await axios
      .get(`${BASE_URL}get-all-services`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const allServices = response.data;
        dispatch(services(allServices));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    if (!loadedUser) return null;
    getAllDetails();
    getServiceGroup();
    getServices();
  }, []);

  if (!loadedUser) return null;

  const { full_name } = loadedUser;

  return (
    <Page title="Dashboard | Naborhood Auto">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          sx={{ pb: 5, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Stack
            style={{
              direction: 'row'
            }}
          >
            <Typography variant="h4">Welcome,</Typography>
            <Typography variant="h2">{full_name}</Typography>
          </Stack>
          <Fabs to="createServiceRequest" />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>
          {/* 
          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

          <Grid item xs={12} md={12} lg={12}>
            <AppNewsUpdate />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
