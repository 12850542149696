import { Icon } from '@iconify/react';
import { useState } from 'react';
import editFill from '@iconify/icons-eva/edit-2-fill';
import { Link as RouterLink } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
// material
import {
  Box,
  Button,
  IconButton,
  TextField,
  Divider,
  Card,
  Paper,
  Container,
  InputAdornment,
  Stack,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../components/Page';
import CustomizedDialogs from '../components/CustomizedDialogs';
import { ServiceQuantityForm, ServiceRequestTable } from '../components/services';
import AutocompleteDialog from '../components/AutocompleteDialog';

// ----------------------------------------------------------------------
const names = [
  'Matilda Jones (matilda.jones@gmail.com)',
  'Sam Smith (sam.smith@gmail.com)',
  'Frank Ocean (frank11@yahoo.com)'
];

const vehicles = ['Mercedes Benz C300 (XA123YG)', 'Toyota Corolla (TL222LAG)'];

const services = [
  'Cooling system repairs (radiator & fans)',
  'Top cylinder service',
  'Fuel system services (fuel pump nozzles)'
];

const useStyles = makeStyles((theme) => ({
  selectAdornment: {
    marginRight: theme.spacing(3)
  }
}));

export default function CreateServiceRequest() {
  const styles = useStyles();
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const [vehicle, setVehicle] = useState('');
  const [value1, setValue1] = useState('');

  const [service, setService] = useState('');
  const [value2, setValue2] = useState('');

  const [close, setClose] = useState('');

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleClose = (event) => {
    setClose(event.target.value);
  };
  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Page title="Edit Service | Naborhood Auto">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" sx={{ mb: 2, color: 'text.secondary', pt: 3 }}>
            Edit Service Request
          </Typography>
        </Stack>

        <Card sx={{ p: 4 }}>
          <Stack spacing={3} sx={{ px: 5, pt: 5 }}>
            <Autocomplete
              value={name}
              onChange={(event, newName) => {
                setName(newName);
              }}
              inputValue={value}
              onInputChange={(event, newValue) => {
                setValue(newValue);
              }}
              id="name"
              options={names}
              // {...getFieldProps('item')}
              // error={Boolean(touched.name && errors.name)}
              // helperText={touched.name && errors.name}
              renderInput={(params) => (
                <TextField helperText="Please select client's name" {...params} label="Name" />
              )}
            />

            <Stack sx={{ py: 3 }}>
              <Autocomplete
                value={vehicle}
                onChange={(event, newVehicle) => {
                  setVehicle(newVehicle);
                }}
                inputValue={value1}
                onInputChange={(event, newValue1) => {
                  setValue1(newValue1);
                }}
                id="vehicle"
                options={vehicles}
                // {...getFieldProps('item')}
                // error={Boolean(touched.name && errors.name)}
                // helperText={touched.name && errors.name}
                renderInput={(params) => (
                  <TextField
                    helperText="Please select vehicle to be serviced"
                    {...params}
                    label="Vehicle"
                  />
                )}
              />
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
            <Stack sx={{ pt: 4, pb: 2 }}>
              <AutocompleteDialog dialogTitle="Service Quantity" />
            </Stack>
            <Paper variant="outlined" sx={{ pt: 2 }} elevation="0">
              <ServiceRequestTable tableName="Selected Service Table" />
            </Paper>
            <Stack sx={{ py: 3 }}>
              <TextField
                id="outlined-multiline-static"
                label="Service Note"
                fullwidth
                multiline
                rows={5}
                helperText="Enter service request notes here"
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              // flexWrap="wrap-reverse"
              spacing={3}
              sx={{ pt: 0, pb: 4 }}
              maxWidth="600"
            >
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                // loading={isSubmitting}
              >
                Discard
              </LoadingButton>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                //   loading={isSubmitting}
              >
                Update
              </LoadingButton>
              {/* </Stack> */}
            </Stack>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
}
