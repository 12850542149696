// import * from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Chip from '@mui/material/Chip';
import { Link, Stack, Checkbox, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// Services
const services = [
  {
    id: 1,
    service: 'Cooling system repairs (radiator & fans)',
    quantity: ''
  },
  { id: 2, service: 'Fuel system services (fuel pump, nozzles)', quantity: '' },
  { id: 3, service: 'Top cylinder services', quantity: '' },
  { id: 4, service: 'Complete engine replacement', quantity: '' }
];

const filter = createFilterOptions();

export default function AutocompleteDialog({ dialogTitle }) {
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      service: '',
      quantity: ''
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    service: '',
    quantity: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      service: dialogValue.service,
      quantity: parseInt(dialogValue.quantity, 10)
    });
    console.log(setValue);
    handleClose();
  };

  return (
    <>
      <Autocomplete
        // multiple
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                service: newValue,
                quantity: ''
              });
            });
          } else {
            toggleOpen(true);
            setValue(newValue);
            setDialogValue({
              service: newValue.service,
              quantity: ''
            });
          }
        }}
        id="autocomplete-dialog"
        options={services}
        getOptionLabel={(option) => {
          //   e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.service;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.service}</li>}
        renderInput={(params) => (
          <TextField helperText="Please select services" {...params} label="Services" />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <Stack spacing={3} sx={{ minWidth: 300 }}>
              <Stack direction="column" spacing={2}>
                <TextField
                  autoFocus
                  id="service"
                  disabled
                  value={dialogValue.service}
                  onChange={(event) =>
                    setDialogValue({
                      ...dialogValue,
                      service: event.target.value
                    })
                  }
                  label="Service"
                  type="text"
                  sx={{ mt: 1 }}
                />

                <TextField
                  id="quantity"
                  value={dialogValue.quantity}
                  onChange={(event) =>
                    setDialogValue({
                      ...dialogValue,
                      quantity: event.target.value
                    })
                  }
                  label="Quantity"
                  type="number"
                  //   {...getFieldProps('quantity')}
                  //   error={Boolean(touched.quantity && errors.quantity)}
                  //   helperText={touched.quantity && errors.quantity}
                />
              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                // loading={isSubmitting}
              >
                Discard
              </LoadingButton>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Stack>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}
