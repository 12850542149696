import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
// material
import { Box, Card, Divider, Typography, Stack } from '@mui/material';
// utils
import { fNumber, fShortenNumber } from '../../utils/formatNumber';
import AnalyticsServiceDetailCard from './AnalyticsServiceDetailCard';
// ----------------------------------------------------------------------

export default function AnalyticsServiceCard({
  title,
  subTitle,
  amount,
  serviceTitle,
  serviceActivity
}) {
  return (
    <Card sx={{ my: 2, borderRadius: 1 }} variant="outlined">
      <Stack display="flex" flexWrap="wrap" spacing={1} sx={{ p: 2.5 }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
        <Typography
          component="span"
          variant="body1"
          sx={{
            color: 'text.disabled',
            fontStyle: 'italic',
            fontSize: 10
          }}
        >
          {subTitle}
        </Typography>
        <Divider />

        <AnalyticsServiceDetailCard
          serviceTitle="Cooling system repairs (radiator & fans)"
          serviceActivity="Attended to 6 vehicles"
          amount="72900"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Shock absorber & stabilizer linkage change"
          serviceActivity="Attended to 3 vehicles"
          amount="67500"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Wheel alignment & balancing"
          serviceActivity="Attended to 9 vehicles"
          amount="39850"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Brake pad & lining, caliper change"
          serviceActivity="Attended to 5 vehicles"
          amount="24750"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Wiring & Battery Services"
          serviceActivity="Attended to 5 vehicles"
          amount="22100"
        />
      </Stack>
    </Card>
  );
}
