import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterNew from './pages/RegisterNew';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import CreateServiceRequest from './pages/CreateServiceRequest';
import EditServiceRequest from './pages/EditServiceRequest';
import ViewServiceRequest from './pages/ViewServiceRequest';
import Blog from './pages/Blog';
import User from './pages/User';
import ViewUser from './pages/ViewUser';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Service from './pages/Service';
import Analytics from './pages/Analytics';
import Inventory from './pages/Inventory';
import ServicePage from './pages/ServicePage';
import Status from './pages/Status';

// ----------------------------------------------------------------------

// export default function Router() {

// return useRoutes(
const routes = (token) => [
  {
    path: '/dashboard',
    element: token ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {
        element: <Navigate to="/dashboard/app" replace />
      },
      { path: 'app', element: <DashboardApp /> },
      {
        path: '/dashboard/app',
        element: <Outlet />,
        children: [{ path: 'createServiceRequest', element: <CreateServiceRequest /> }]
      },
      { path: 'user', element: <User /> },
      { path: 'products', element: <Products /> },
      {
        path: '/dashboard/products',
        element: <Outlet />,
        children: [
          { path: 'createServiceRequest', element: <CreateServiceRequest /> },
          { path: 'EditServiceRequest', element: <EditServiceRequest /> },
          { path: 'ViewServiceRequest', element: <ViewServiceRequest /> }
        ]
      },
      { path: 'blog', element: <Blog /> },
      { path: 'profile', element: <Profile /> },
      { path: 'settings', element: <Settings /> },
      {
        path: '/dashboard/user',
        element: <Outlet />,
        children: [
          { path: 'registerNew', element: <RegisterNew /> },
          { path: 'viewUser', element: <ViewUser /> }
        ]
      },
      { path: 'changePassword', element: <ChangePassword /> },
      { path: 'service', element: <Service /> },
      {
        path: '/dashboard/service',
        element: <Outlet />,
        children: [{ path: ':post', element: <ServicePage /> }]
      },
      { path: 'analytics', element: <Analytics /> },
      { path: 'inventory', element: <Inventory /> },
      { path: 'status', element: <Status /> }
    ]
  },
  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: 'login', element: <Login /> },
      {
        path: '/login',
        element: <Outlet />,
        children: [{ path: 'forgotPassword', element: <ForgotPassword /> }]
      },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/dashboard/app" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  { path: '*', element: <Navigate to="/404" replace /> }
];
// }
export default routes;
