import { useFormik } from 'formik';
import { useState } from 'react';
// icon
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
// material
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import cx from 'clsx';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
// components
import Page from '../components/Page';
import {
  AnalyticsCustomerCard,
  AnalyticsServiceGroupCard,
  AnalyticsServiceCard,
  AnalyticsCard,
  AnalyticsTable
} from '../components/analytics.js';
// mocks
import PRODUCTS from '../_mocks_/products';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'initial',
    backgroundColor: 'transparent'
  },

  content: {
    zIndex: 9,
    position: 'relative',
    margin: '-7% 0px 0'
  },

  card: {
    position: 'relative',
    backgroundColor: '#e5fcfb'
  },

  shell: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(70%, 50%)',
    borderRadius: '50%',
    backgroundColor: 'rgba(71, 167, 162, 0.12)',
    padding: '40%',
    '&:before': {
      position: 'absolute',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: '-16%',
      backgroundColor: 'rgba(71, 167, 162, 0.08)'
    }
  }
}));

export default function Analytics() {
  const styles = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dateValue, setDateValue] = useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  return (
    <Page title="Dashboard: Analytics | Naborhood Auto">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Analytics
        </Typography>

        <Box sx={{ width: '100%', typography: 'body1' }} elevation={0} className={styles.root}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="Tabs">
                <Tab label="Overview" value="1" />
                <Tab label="Details" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <Card sx={{ borderRadius: 0 }} className={cx(styles.card)}>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 4, my: 3 }}
                >
                  <Typography variant="h3" sx={{ mb: 2, color: 'text.secondary', pt: 3 }}>
                    You have earned{' '}
                    <Typography
                      component="span"
                      variant="h3"
                      sx={{
                        color: 'text.primaryMain'
                      }}
                    >
                      <Typography
                        component="span"
                        variant="h3"
                        sx={{
                          textDecoration: 'line-through'
                        }}
                      >
                        N
                      </Typography>
                      &nbsp;850,000.00
                    </Typography>
                    <br />
                    this month
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 5, pb: 5, color: 'text.secondary' }}>
                    Top earning service is Engine Maintenance Services, rendering services to 9
                    customers, and generating ₦78,000.00
                  </Typography>
                </Stack>
                <div className={styles.shell} />
              </Card>
              <Box sx={{ px: 2.5 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    className={cx(styles.content)}
                    sx={{ display: 'flex', pb: 2 }}
                  >
                    <AnalyticsCard
                      title="Last Month, November"
                      amount="95300"
                      bottomCaption="Highest earner was Engine maintenance with N 56,000.00"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    className={cx(styles.content)}
                    sx={{ display: 'flex', pb: 2 }}
                  >
                    <AnalyticsCard
                      title="This year, 2021"
                      amount="1500400"
                      bottomCaption="Total revenue for time period"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className={cx(styles.content)}
                    sx={{ display: 'flex', pb: 2 }}
                  >
                    <AnalyticsCard
                      title="All time"
                      amount="4050600"
                      bottomCaption="Started keeping records 6 months agp"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={2}
                sx={{ mt: 5, px: 4, alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography variant="h4" sx={{ color: 'text.secondary', pb: 2 }}>
                  Performance &nbsp;
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      color: 'text.disabled',
                      fontStyle: 'italic',
                      fontSize: 12
                    }}
                  >
                    This month
                  </Typography>
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
                  >
                    <DesktopDatePicker
                      label="From"
                      inputFormat="MM/dd/yyyy"
                      value={dateValue}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <KeyboardTabIcon />
                    <DesktopDatePicker
                      label="To"
                      inputFormat="MM/dd/yyyy"
                      value={dateValue}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>

              <Box sx={{ p: 2.5, mt: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <AnalyticsServiceGroupCard
                      title="Service Groups"
                      subTitle="Calculated based on the cumulative service group earnings"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <AnalyticsServiceCard
                      title="Services"
                      subTitle="Calculated based on the cumulative services earnings"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <AnalyticsCustomerCard
                      title="Customer Expenditure"
                      subTitle="Calculated based on customer expenditure"
                    />
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>

            {/* second tab */}
            <TabPanel value="2" sx={{ px: 0 }}>
              <Card sx={{ borderRadius: 0 }}>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ px: 4, pt: 4, pb: 2, mt: 2, alignItems: 'center' }}
                    >
                      <DesktopDatePicker
                        label="From"
                        inputFormat="MM/dd/yyyy"
                        value={dateValue}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <KeyboardTabIcon />
                      <DesktopDatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        value={dateValue}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                  <AnalyticsTable tableName="" />
                </Stack>
              </Card>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
