import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function AddItemForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const AddItemSchema = Yup.object().shape({
    item: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Item name is required'),
    price: Yup.string().min(1, 'Price too small').required('Item price is required'),
    quantity: Yup.string().min(0, 'quantity too small').required('Item quantity is required')
  });

  const formik = useFormik({
    initialValues: {
      item: '',
      price: '',
      quantity: ''
    },
    validationSchema: AddItemSchema,
    onSubmit: () => {
      navigate('/dashboard/inventory', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ minWidth: 300 }}>
          <Stack direction="column" spacing={2}>
            <TextField
              fullWidth
              label="Item Name"
              {...getFieldProps('item')}
              error={Boolean(touched.item && errors.item)}
              helperText={touched.item && errors.item}
            />
            <TextField
              fullWidth
              type="number"
              label="Price"
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
              InputProps={{
                startAdornment: <InputAdornment position="start">₦</InputAdornment>
              }}
            />
            <TextField
              fullWidth
              type="number"
              label="Quantity"
              {...getFieldProps('quantity')}
              error={Boolean(touched.quantity && errors.quantity)}
              helperText={touched.quantity && errors.quantity}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Discard
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
