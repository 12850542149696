// material
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function Fabs({ to }) {
  return (
    <Tooltip title="Create New Service Request" arrow>
      {/* <Link component=> */}
      <Fab color="text.danger" aria-label="add" component={RouterLink} to={to}>
        <AddIcon />
      </Fab>
      {/* </Link> */}
    </Tooltip>
  );
}
