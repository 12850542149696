import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import UploadIcon from '@mui/icons-material/Upload';

// material
import {
  Link,
  Stack,
  Checkbox,
  InputLabel,
  Input,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUpload from '../FIleUpload';

// ----------------------------------------------------------------------

export default function ServiceGroupAddForm() {
  const navigate = useNavigate();

  //   const handleFileUpload = () => {};

  //   const imageUpload = (e: ChangeEvent<HTMLInputElement>) => {
  //   // the first/only file selected by user
  //   if (e.target.files?.item(0)) {
  //     onChange(value.image, "image", e.target.files?.item(0)!.name);
  //   }

  //   // Focus textfield
  //   if (imageInputRef.current) {
  //     imageInputRef.current.focus();
  //   }
  // };

  const [value, setValue] = useState('');

  const ServiceGroupAddSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('service group name is required'),
    upload: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      upload: ''
    },
    validationSchema: ServiceGroupAddSchema,
    onSubmit: () => {
      navigate('/dashboard/inventory', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ minWidth: 300 }}>
          <Stack direction="column" spacing={2}>
            <TextField
              fullWidth
              label="Service Group Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <FileUpload />

            {/* <TextField
              //   id={`sim-script-${sim.uuid}`}
              label="Upload Cover Image"
              //   inputRef={scriptInputRef} // To focus on the field after clicking icon
              value={value.upload}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <>
                    <Tooltip title="Upload Service Group Cover Image" arrow>
                      <IconButton aria-label="upload" component="label">
                        <UploadIcon />
                        <input hidden type="file" onChange={handleFileUpload} />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              }}
            /> */}
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Discard
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
