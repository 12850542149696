import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-2-fill';
import { Link as RouterLink } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// material
import { Grid, Button, Box, Card, Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ViewUserTable } from '../components/_dashboard/user';
// components
import { BiodataForm, UserAvatar } from '../components/profile/bio-data';
import { VehicleAvatar, VehicleForm } from '../components/profile/vehicle';
import AccordionBody from '../components/AccordionBody';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function ViewUser() {
  return (
    <Page title="User | Naborhood Auto">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" sx={{ mb: 2, color: 'text.secondary', pt: 3 }}>
            Viewing user:{' '}
            <Typography
              component="span"
              variant="h3"
              sx={{
                color: 'text.primaryMain'
              }}
            >
              Matilda Jones
            </Typography>
          </Typography>
        </Stack>

        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ borderRadius: 0 }}>
            <AccordionBody title="Bio-Data">
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 5, pt: 3, mb: 0 }}
              >
                <UserAvatar name="Matilda Jones" />
                <BiodataForm />
              </Stack>
            </AccordionBody>

            <AccordionBody title="Vehicle Details">
              <Stack
                direction="row"
                flexWrap="wrap-reverse"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 5, pt: 3, mb: 0 }}
              >
                <VehicleForm />
                <VehicleAvatar name="Toyota Avalon" />
              </Stack>
            </AccordionBody>

            <AccordionBody title="Account Status">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                my={3}
                px={4}
              >
                <Stack
                  direction={{ xs: 'row', sm: 'row' }}
                  spacing={3}
                  sx={{ alignItems: 'center' }}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Role</FormLabel>
                    <RadioGroup row aria-label="role" name="row-radio-buttons-group">
                      <FormControlLabel value="user" control={<Radio />} label="User" />
                      <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{
                    color: 'text.secondary',
                    fontSize: 16
                  }}
                >
                  Date Created:
                  <br />
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ pr: 4, color: 'text.disabled' }}
                  >
                    Wed, Mar 11, 2020 1:52 PM
                  </Typography>
                </Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                my={3}
                px={4}
              >
                <Stack
                  direction={{ xs: 'row', sm: 'row' }}
                  spacing={3}
                  sx={{ alignItems: 'center' }}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status</FormLabel>
                    <RadioGroup row aria-label="status" name="row-radio-buttons-group">
                      <FormControlLabel value="active" control={<Radio />} label="Active" />
                      <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{
                    color: 'text.secondary',
                    fontSize: 16
                  }}
                >
                  Last Updated:
                  <br />
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ pr: 4, color: 'text.disabled' }}
                  >
                    Sun, May 24, 2020 8:50 AM
                  </Typography>
                </Typography>
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={3}
                sx={{ pt: 2, px: 4 }}
                maxWidth="530"
              >
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="outlined"
                  // loading={isSubmitting}
                >
                  Discard Changes
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  //   loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            </AccordionBody>

            <AccordionBody title="Service History">
              <ViewUserTable />
            </AccordionBody>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
