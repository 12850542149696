import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUpload from '../FIleUpload';

// ----------------------------------------------------------------------

export default function ServiceGroupEditForm() {
  const navigate = useNavigate();

  const ServiceGroupEditSchema = Yup.object().shape({
    item: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Item name is required')
    // price: Yup.string().min(1, 'Price too small').required('Item price is required'),
    // quantity: Yup.string().min(0, 'quantity too small').required('Item quantity is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      upload: ''
    },
    validationSchema: ServiceGroupEditSchema,
    onSubmit: () => {
      navigate('/dashboard/inventory', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ minWidth: 300 }}>
          <Stack direction="column" spacing={2}>
            <TextField
              fullWidth
              label="Service Group Name"
              {...getFieldProps('item')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <FileUpload />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Discard
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
