import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
//
import POSTS from '../_mocks_/blog';
import { AppOrderTimeline } from '../components/_dashboard/app';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function Status() {
  return (
    <Page title="Dashboard: Profile | Naborhood Auto">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Status
          </Typography>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="flex-end">
          <BlogPostsSearch posts={POSTS} />
          {/* <BlogPostsSort options={SORT_OPTIONS} /> */}
        </Stack>

        <Grid item xs={12} md={12} lg={12}>
          <AppOrderTimeline />
        </Grid>
      </Container>
    </Page>
  );
}
