import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceGroup: null
};
const serviceGroupSlice = createSlice({
  name: 'serviceGroup',
  initialState,

  reducers: {
    serviceGroup: (state = initialState, action) => {
      state.serviceGroup = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
const { reducer, actions } = serviceGroupSlice;

export const { serviceGroup } = actions;

export default reducer;
