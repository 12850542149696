import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useParams } from 'react-router-dom';
// useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
import { ProductList } from '../components/_dashboard/products';
//
import PRODUCTS from '../_mocks_/products';
//
import POSTS from '../_mocks_/blog';
import { ServiceAddForm } from '../components/services';
import CustomizedDialogs from '../components/CustomizedDialogs';
//
import { getService } from '../store/service/getServiceSlice';
// mock images
import { mockImgCover } from '../utils/mockImages';
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function ServicePage() {
  const { post } = useParams();

  const [groupName, setGroupName] = useState('');

  // service data
  const loadedGetService = useSelector((state) => state.getService.getService);

  const loadedServiceGroup = useSelector((state) => state.serviceGroup.serviceGroup);

  const serviceGroupData = loadedServiceGroup.data.service_group_detail;

  const dispatch = useDispatch();
  const accessToken = JSON.parse(localStorage.getItem('naborhood'));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { token } = accessToken;

  const getServicesById = async () => {
    await axios
      .get(`${BASE_URL}get-service/${post}`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const getServiceById = response.data;
        dispatch(getService(getServiceById));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getServicesById();

    serviceGroupData.forEach(getServiceGroupName);

    function getServiceGroupName(item) {
      if (item.id === Number(post)) {
        const selectedServiceName = item.service_group_name;
        setGroupName(selectedServiceName);
      }
    }
  }, []);

  const getServiceByGroupId = [
    ...Array(loadedGetService ? loadedGetService.data.service_detail.length : 0)
  ].map((_, index) => ({
    id: loadedGetService.data.service_detail[index].id,
    cover: mockImgCover(index + 1),
    name: loadedGetService.data.service_detail[index].service_name,
    price: loadedGetService.data.service_detail[index].service_price
  }));

  return (
    <Page title="Dashboard: Blog | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {groupName}
          </Typography>
          <CustomizedDialogs
            title="Add Service"
            linkName="Add Service"
            variant="contained"
            icon={<Icon icon={plusFill} />}
          >
            <ServiceAddForm />
          </CustomizedDialogs>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack>

        <ProductList products={getServiceByGroupId} id={post} />
      </Container>
    </Page>
  );
}
