import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
// material
import { Box, Card, Divider, Typography, Stack } from '@mui/material';
// utils
import { fNumber, fShortenNumber } from '../../utils/formatNumber';
import AnalyticsServiceDetailCard from './AnalyticsServiceDetailCard';
// ----------------------------------------------------------------------

export default function AnalyticsServiceGroupCard({
  title,
  subTitle,
  amount,
  serviceTitle,
  serviceActivity
}) {
  return (
    <Card sx={{ my: 2, borderRadius: 1 }} variant="outlined">
      <Stack display="flex" flexWrap="wrap" spacing={1} sx={{ p: 2.5 }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
        <Typography
          component="span"
          variant="body1"
          sx={{
            color: 'text.disabled',
            fontStyle: 'italic',
            fontSize: 10
          }}
        >
          {subTitle}
        </Typography>
        <Divider />
        <AnalyticsServiceDetailCard
          serviceTitle="Engine maintenance Services"
          serviceActivity="Attended to 6 vehicles"
          amount="93500"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Lube oil & filter change Services"
          serviceActivity="Attended to 3 vehicles"
          amount="63770"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Tires & Wheel Services"
          serviceActivity="Attended to 9 vehicles"
          amount="33850"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Legs Repair Services"
          serviceActivity="Attended to 5 vehicles"
          amount="23800"
        />
        <AnalyticsServiceDetailCard
          serviceTitle="Wiring & Battery Services"
          serviceActivity="Attended to 5 vehicles"
          amount="23800"
        />
      </Stack>
    </Card>
  );
}
