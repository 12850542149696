import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Stack,
  Link,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent
} from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
//
import SvgIconStyle from '../../SvgIconStyle';
import { ServiceGroupEditForm } from '../../services';
import CustomizedDialogs from '../../CustomizedDialogs';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  // marginTop: theme.spacing(3),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function BlogPostCard({ post, index, serviceButton, icon, to }) {
  const { cover, title, comment, author, createdAt } = post;
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;

  const POST_INFO = [{ number: comment, icon: messageCircleFill }];

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle>
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute'
            }}
          />
          <AvatarStyle alt={author.name} src={author.avatarUrl} />

          <CoverImgStyle alt={title} src={cover} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {author.name} ({fDate(createdAt)})
          </Typography>

          <TitleStyle
            to={to}
            color="inherit"
            variant="subtitle3"
            underline="hover"
            component={RouterLink}
            sx={{ fontSize: 16 }}
          >
            {title}
          </TitleStyle>
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 2 }}
          >
            <InfoStyle
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {POST_INFO.map((info, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {/* <Box component={Icon} icon={info.icon} sx={{ width: 16, height: 16, mr: 0 }} /> */}

                  {/* <Typography
                    variant="caption"
                    sx={{ fontSize: 20, textDecoration: 'line-through' }}
                  >
                    N
                  </Typography> */}
                  {/* 
                  <Typography variant="caption" sx={{ fontSize: 20, ml: 0.5 }}>
                    {fCurrency(info.number)}
                  </Typography> */}
                </Box>
              ))}
            </InfoStyle>

            <CustomizedDialogs
              title="Edit Service Group"
              linkName="Edit"
              variant="contained"
              icon={<Icon icon={editFill} />}
            >
              <ServiceGroupEditForm />
            </CustomizedDialogs>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}
