import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null
};
const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    user: (state = initialState, action) => {
      state.user = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
const { reducer, actions } = userSlice;

export const { user } = actions;

export default reducer;
