/* eslint-disable camelcase */
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { user } from '../../../store/user/userSlice';
// ----------------------------------------------------------------------

export default function BiodataForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = JSON.parse(localStorage.getItem('naborhood'));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [dateValue, setDateValue] = useState(new Date('1988-08-26T21:11:54'));

  const { token } = accessToken;

  const userProfile = useSelector((state) => state.profile);

  const loadedUser = useSelector((state) => state.login.user);

  const editProfileUrl = `${BASE_URL}edit-profile`;
  // const getProfileUrl = `${BASE_URL}get-profile/${id}`;

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    // .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    // .required('Last name required'),
    email: Yup.string().email('Email must be a valid email address'),
    // .required('Email is required'),
    phone: Yup.string().min(10, 'Phone number must not be less than 11 numbers'),
    // .required('Phone number is required'),
    dob: Yup.string(),
    gender: Yup.string()
  });

  const editUserProfile = async ({ formik }) => {
    try {
      await axios
        .get(editProfileUrl, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
          },
          first_name: formik.values.firstName,
          last_name: formik.values.lastName,
          email: formik.values.email,
          phone: formik.values.phone,
          dob: formik.values.dob,
          gender: formik.values.gender
        })
        .then((response) => {
          const userProfile = response.data;
          dispatch(user(userProfile));
          // console.log(userProfile);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      dob: '',
      gender: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      editUserProfile(formik);
    }
  });

  if (!loadedUser) return null;

  const { first_name, last_name, phone, email, gender, dob } = loadedUser;

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              type="number"
              label="Phone number"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ alignItems: 'center' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of Birth"
                inputFormat="mm/dd/yyyy"
                value={dateValue}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              // loading={isSubmitting}
            >
              Discard Changes
            </LoadingButton>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
