import { useFormik } from 'formik';
import { useState } from 'react';
// material
import { Card, Container, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// components
import Page from '../components/Page';
import {
  HistoryTable,
  HistoryInProgressTable,
  HistoryCompletedTable,
  HistoryPendingTable
} from '../components/history';
import Fabs from '../components/Fabs';
//

// ----------------------------------------------------------------------

export default function History() {
  const [openFilter, setOpenFilter] = useState(false);
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const formik = useFormik({
  //   initialValues: {
  //     gender: '',
  //     category: '',
  //     colors: '',
  //     priceRange: '',
  //     rating: ''
  //   },
  //   onSubmit: () => {
  //     setOpenFilter(false);
  //   }
  // });

  // const { resetForm, handleSubmit } = formik;

  // const handleOpenFilter = () => {
  //   setOpenFilter(true);
  // };

  // const handleCloseFilter = () => {
  //   setOpenFilter(false);
  // };

  // const handleResetFilter = () => {
  //   handleSubmit();
  //   resetForm();
  // };

  return (
    <Page title="History | Naborhood Auto">
      <Container>
        <Stack
          direction="row"
          sx={{ mb: 5, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h4">Services</Typography>
          <Fabs to="createServiceRequest" />
        </Stack>
        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="Tabs" variant="fullWidth">
                  <Tab label="New" value="1" />
                  <Tab label="In-Progress" value="2" />
                  <Tab label="Pending" value="4" />
                  <Tab label="Completed" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ px: 0 }}>
                <HistoryTable tableName="New" />
              </TabPanel>

              <TabPanel value="2" sx={{ px: 0 }}>
                <HistoryInProgressTable tableName="In-Progress" />
              </TabPanel>

              <TabPanel value="4" sx={{ px: 0 }}>
                <HistoryPendingTable tableName="Pending" />
              </TabPanel>

              <TabPanel value="3" sx={{ px: 0 }}>
                <HistoryCompletedTable tableName="Completed" />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
