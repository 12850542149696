import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function VehicleForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string()
      .min(10, 'Phone number must not be less than 11 numbers')
      .required('Phone number is required'),
    password: Yup.string().required('Password is required'),
    brand: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    model: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    year: Yup.string().min(4, 'Too short!'),
    plate: Yup.string().min(4, 'Too short!'),
    colour: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    mileage: Yup.string().min(4, 'Too short!')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      brand: '',
      model: '',
      year: '',
      plate: '',
      colour: '',
      mileage: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="Brand"
              {...getFieldProps('brand')}
              error={Boolean(touched.brand && errors.brand)}
              helperText={touched.brand && errors.brand}
            />

            <TextField
              fullWidth
              label="Model"
              {...getFieldProps('model')}
              error={Boolean(touched.model && errors.model)}
              helperText={touched.model && errors.model}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              type="number"
              label="Year"
              {...getFieldProps('year')}
              error={Boolean(touched.year && errors.year)}
              helperText={touched.year && errors.year}
            />

            <TextField
              fullWidth
              label="Number Plate"
              {...getFieldProps('plate')}
              error={Boolean(touched.plate && errors.plate)}
              helperText={touched.plate && errors.plate}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <TextField
              fullWidth
              label="Colour"
              {...getFieldProps('colour')}
              error={Boolean(touched.colour && errors.colour)}
              helperText={touched.colour && errors.colour}
            />

            <TextField
              fullWidth
              type="number"
              label="Mileage"
              {...getFieldProps('mileage')}
              error={Boolean(touched.mileage && errors.mileage)}
              helperText={touched.mileage && errors.mileage}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              // loading={isSubmitting}
            >
              Discard Changes
            </LoadingButton>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
