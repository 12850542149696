// material
import { Icon } from '@iconify/react';
import { Box, Typography, Stack } from '@mui/material';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
// components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// ----------------------------------------------------------------------

// const RootStyle = styled(AccordionSummary)(({ theme }) => ({
//   boxShadow: 'none',
//   textAlign: 'center',
//   padding: theme.spacing(5, 0),
//   color: theme.palette.primary.darker,
//   backgroundColor: theme.palette.primary.lighter
// }));

export default function ServiceAccordion({ title, children }) {
  return (
    <Accordion>
      <AccordionSummary
        // width="100%"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          bgcolor: 'text.primaryLighter'
          //   border: '1px solid rgba(0, 0, 0, .125)'
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
          //   spacing={1}
          sx={{ px: 2.5, pt: 3, mb: 3, color: 'text.secondary' }}
        >
          <Stack>
            <Typography variant="subtitle1" sx={{}}>
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ align: 'right' }}>
            <Box sx={{ px: 2 }}>
              <Tooltip title="Edit" arrow>
                <Icon icon={editFill} width={24} height={24} />
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Delete" arrow>
                <Icon icon={trash2Outline} width={24} height={24} sx={{}} />
              </Tooltip>
            </Box>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
