import { useState } from 'react';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-2-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import stockFill from '@iconify/icons-eva/archive-fill';
import { Link as RouterLink } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';

// material
import {
  Grid,
  Button,
  Box,
  Card,
  Container,
  Stack,
  Tooltip,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// components

import { PartsUsedTable } from '../components/services';
import AccordionBody from '../components/AccordionBody';
import ServiceAccordion from '../components/ServiceAccordion';
import Page from '../components/Page';
import { AddItemForm, AddStockForm, EditItemForm } from '../components/inventory';
import CustomizedDialogs from '../components/CustomizedDialogs';

// ----------------------------------------------------------------------

const parts = ['Engine oil', 'Break fluid', 'Gasket', 'Spark plugs'];

export default function ViewServiceRequest() {
  const [value, setValue] = useState('1');

  const [part, setPart] = useState('');
  const [value2, setValue2] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePartChange = (event) => {
    setPart(event.target.value);
  };

  return (
    <Page title="User | Naborhood Auto">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" sx={{ mb: 2, color: 'text.secondary', pt: 3 }}>
            Viewing details for:{' '}
            <Typography
              component="span"
              variant="h3"
              sx={{
                color: 'text.primaryMain'
              }}
            >
              Matilda Jones
            </Typography>
          </Typography>
        </Stack>

        {/* <Grid item xs={12} md={12} lg={12}> */}
        <Card sx={{ borderRadius: 0 }}>
          <AccordionBody title="User Details">
            <Stack
              direction="column"
              flexWrap="wrap"
              alignItems="left"
              //   justifyContent="start"
              sx={{ px: 5, pt: 3, mb: 0 }}
            >
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  pb: 2,
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Name:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  Matilda Jones
                </Typography>
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  pb: 2,
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Email:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  matilda.jones@gmail.com
                </Typography>
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Phone:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  0803 6820 434
                </Typography>
              </Typography>
            </Stack>
          </AccordionBody>

          <AccordionBody title="Vehicle Details">
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-between"
              sx={{ pr: 5, pl: 5, pt: 3, mb: 0 }}
            >
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  pb: 2,
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Brand:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  Toyota
                </Typography>
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  pb: 2,
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Model:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  Avalon
                </Typography>
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  pb: 2,
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Year / Colour:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  2014 (Black)
                </Typography>
              </Typography>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  pb: 2,
                  color: 'text.primaryMain',
                  fontSize: 16
                }}
              >
                Number Plate:
                <br />
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ pr: 4, color: 'text.secondary', fontSize: 22 }}
                >
                  XA 156 ABJ
                </Typography>
              </Typography>
            </Stack>
          </AccordionBody>

          <AccordionBody title="Services" sx={{ px: 4 }}>
            <Card>
              <ServiceAccordion title="Tire Repair">
                <Box>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="Tabs">
                        <Tab label="Parts Used" value="1" />
                        <Tab label="Service Notes" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0 }}>
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ py: 2, px: 4 }}
                      >
                        <Autocomplete
                          sx={{ minWidth: 300 }}
                          value={part}
                          onChange={(event, newPart) => {
                            setPart(newPart);
                          }}
                          inputValue={value2}
                          onInputChange={(event, newValue2) => {
                            setValue2(newValue2);
                          }}
                          id="part"
                          options={parts}
                          // {...getFieldProps('item')}
                          // error={Boolean(touched.name && errors.name)}
                          // helperText={touched.name && errors.name}
                          renderInput={(params) => (
                            <TextField helperText="Please select parts" {...params} label="Parts" />
                          )}
                        />
                        <Stack
                          direction="row"
                          flexWrap="wrap"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                          sx={{ pl: 2.5, pt: 3, mb: 3, color: 'text.Secondary' }}
                        >
                          <CustomizedDialogs
                            title="Add New Item"
                            linkName="Add Item"
                            variant="text"
                            icon={<Icon icon={plusFill} />}
                          >
                            <AddItemForm />
                          </CustomizedDialogs>

                          <CustomizedDialogs
                            title="Edit Item"
                            linkName="Edit Item"
                            variant="text"
                            icon={<Icon icon={editFill} />}
                          >
                            <EditItemForm />
                          </CustomizedDialogs>

                          <CustomizedDialogs
                            title="Add Stock"
                            linkName="Add Stock"
                            variant="text"
                            icon={<Icon icon={stockFill} />}
                          >
                            <AddStockForm />
                          </CustomizedDialogs>
                        </Stack>
                      </Stack>
                      <PartsUsedTable tableName="" />
                    </TabPanel>

                    <TabPanel value="2" sx={{ px: 0 }}>
                      <Stack sx={{ py: 3 }}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Service Note"
                          fullwidth
                          multiline
                          rows={5}
                          helperText="Enter service request notes here"
                        />
                      </Stack>
                    </TabPanel>
                  </TabContext>
                </Box>
              </ServiceAccordion>
              <Stack
                direction="row"
                spacing={3}
                alignSelf="right"
                sx={{ pt: 5, pb: 5, mx: 4 }}
                maxWidth="20em"
              >
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  //   loading={isSubmitting}
                >
                  Completed
                </LoadingButton>
              </Stack>
            </Card>
          </AccordionBody>
          <AccordionBody title="Service Summary">
            <Box>
              <Typography>Service Summary</Typography>
            </Box>
          </AccordionBody>
        </Card>
        {/* </Grid> */}
      </Container>
    </Page>
  );
}
