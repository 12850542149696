import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import plusCircleFill from '@iconify/icons-eva/plus-circle-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import listFill from '@iconify/icons-eva/list-fill';
import fileFill from '@iconify/icons-eva/activity-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import barChartFill from '@iconify/icons-eva/bar-chart-fill';
import arrowUpFill from '@iconify/icons-eva/arrowhead-up-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'services',
    path: '/dashboard/products',
    icon: getIcon(listFill)
  },
  {
    title: 'sections',
    path: '/dashboard/service',
    icon: getIcon(plusCircleFill)
  },
  {
    title: 'inventory',
    path: '/dashboard/inventory',
    icon: getIcon(arrowUpFill)
  },
  {
    title: 'status',
    path: '/dashboard/status',
    icon: getIcon(fileFill)
  },
  // {
  //   title: 'reports',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  {
    title: 'analytics',
    path: '/dashboard/analytics',
    icon: getIcon(barChartFill)
  }
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // }
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
