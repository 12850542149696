import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Avatar, Card, Button, Container, Link, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

export default function Profile({ name }) {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatarVehicle(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 400,
        height: 300,
        mb: 3,
        fontSize: '150px'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
  }

  return (
    <Box sx={{ py: 0, mb: 2 }}>
      <Avatar {...stringAvatarVehicle(`${name}`)} variant="rounded" />

      <Link
        href="#"
        underline="hover"
        sx={{
          mt: 4,
          color: 'text.danger'
        }}
      >
        Remove Picture
      </Link>
    </Box>
  );
}
