import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUpload from '../FIleUpload';

// ----------------------------------------------------------------------

export default function ServiceAddForm() {
  const navigate = useNavigate();

  const ServiceAddSchema = Yup.object().shape({
    nameGroup: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('service group name is required'),
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('service name is required'),
    price: Yup.string().min(1, 'Price too small').required('service price is required')
  });

  const formik = useFormik({
    initialValues: {
      nameGroup: '',
      name: '',
      price: '',
      upload: ''
    },
    validationSchema: ServiceAddSchema,
    onSubmit: () => {
      navigate('/dashboard/service/servicePage', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ minWidth: 300 }}>
          <Stack direction="column" spacing={2}>
            <TextField
              fullWidth
              disabled
              label="Service Group Name"
              {...getFieldProps('nameGroup')}
              error={Boolean(touched.nameGroup && errors.nameGroup)}
              helperText={touched.nameGroup && errors.nameGroup}
            />
            <TextField
              fullWidth
              label="Service Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              type="number"
              label="Price"
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
              InputProps={{
                startAdornment: <InputAdornment position="start">₦</InputAdornment>
              }}
            />

            <FileUpload />
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ pt: 5 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Discard
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
