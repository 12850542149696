import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
// material
import { Box, Card, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fNumber, fShortenNumber } from '../../utils/formatNumber';
// ----------------------------------------------------------------------

export default function AnalyticsCard({ title, amount, bottomCaption }) {
  return (
    <Card sx={{ my: 2, width: '100%' }}>
      <Stack display="flex" flexWrap="wrap" sx={{ p: 2 }}>
        <Typography
          variant="caption"
          sx={{ display: 'block', fontSize: 14, color: 'text.secondary' }}
        >
          {title}
        </Typography>

        <Stack direction="row" flexWrap="wrap" spacing={1}>
          <Typography variant="h3" sx={{ color: 'text.primaryMain' }}>
            <Typography
              component="span"
              variant="h3"
              sx={{
                textDecoration: 'line-through'
              }}
            >
              N
            </Typography>
            &nbsp;
            {fShortenNumber(amount)}
          </Typography>
        </Stack>
        <Typography
          variant="caption"
          sx={{ color: 'text.disabled', display: 'block', fontSize: 12 }}
        >
          {bottomCaption}
        </Typography>
      </Stack>
    </Card>
  );
}
