import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  services: null
};
const serviceSlice = createSlice({
  name: 'services',
  initialState,

  reducers: {
    services: (state = initialState, action) => {
      state.services = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
const { reducer, actions } = serviceSlice;

export const { services } = actions;

export default reducer;
