import * as Yup from 'yup';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// use dispatch
import { useDispatch } from 'react-redux';
// reducer
import { register } from '../../../store/login/loginSlice';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string()
      .min(10, 'Phone number must not be less than 11 numbers')
      .required('Phone number is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      try {
        await axios
          .post(`${BASE_URL}register`, {
            headers: {
              Accept: 'application / json',
              'Content-Type': 'application/json; charset=utf-8'
            },
            first_name: formik.values.firstName,
            last_name: formik.values.lastName,
            email: formik.values.email,
            phone: formik.values.phone,
            password: formik.values.password
          })
          .then((response) => {
            const foundUser = response.data;
            const { status, message, data, errors } = foundUser;
            if (status) {
              dispatch(register(data));
              setEmailError('');
              setPhoneError('');
              localStorage.setItem('naborhood', JSON.stringify({ token: data.token }));
              navigate('/', { replace: true });
            } else {
              const emailError = errors.email ? errors.email[0] : '';
              const phoneError = errors.phone ? errors.phone[0] : '';
              setEmailError(emailError);
              setPhoneError(phoneError);
            }
          })
          .catch((e) => {
            console.log(e.message);
            // setEmailError('Network Error, please try again!');
          });
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            type="number"
            label="Phone number"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone) || phoneError}
            helperText={(touched.phone && errors.phone) || phoneError}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email) || emailError}
            helperText={(touched.email && errors.email) || emailError}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            // loading={isLoading ? '' : isSubmitting}
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
