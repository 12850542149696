/* eslint-disable camelcase */
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// use dispatch
import { useDispatch, useSelector } from 'react-redux';
// logout
import { userLogout } from '../../api/authApi';
//
import account from '../../_mocks_/account';
// reducer
import { logout } from '../../store/login/loginSlice';
// components
import MenuPopover from '../../components/MenuPopover';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: 'app'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: 'profile'
  },
  {
    label: 'Change Password',
    icon: keypadFill,
    linkTo: 'changePassword'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const accessToken = JSON.parse(localStorage.getItem('naborhood'));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const logoutUrl = `${BASE_URL}logout`;

  const { token } = accessToken;

  const loadedUser = useSelector((state) => state.login.user);

  if (!loadedUser) return null;

  const { full_name, email } = loadedUser;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await axios
        .get(logoutUrl, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          const logoutMsg = response.data;
          const { message } = logoutMsg;
          dispatch(logout());
          localStorage.removeItem('naborhood');
          navigate('/', { replace: true });
        })
        .catch((e) => {
          console.log(e.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {full_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            // component={RouterLink} to="/login"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
