import PropTypes from 'prop-types';
// material
import { Card, Divider, Grid, Typography, Stack } from '@mui/material';
// utils
import { fShortenNumber } from '../../utils/formatNumber';
// ----------------------------------------------------------------------

export default function AnalyticsServiceDetailCard({ amount, serviceTitle, serviceActivity }) {
  return (
    // <Stack
    //   direction="row"
    //   flexWrap="wrap"
    //   spacing={2}
    //   sx={{ alignItems: 'center', justifyContent: 'space-between', pt: 2 }}
    // >
    <Grid container spacing={1}>
      <Grid item xs={9} sm={9} md={9} lg={9}>
        <Stack display="flex" flexWrap="wrap">
          <Typography variant="subtitle1" sx={{ color: 'text.secondary', fontSize: 12 }}>
            {serviceTitle}
          </Typography>
          <Typography
            component="span"
            variant="body1"
            sx={{
              color: 'text.disabled',
              fontStyle: 'italic',
              fontSize: 10
            }}
          >
            {serviceActivity}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} display="flex" sx={{ justifyContent: 'flex-end' }}>
        <Typography variant="subtitle" sx={{ color: 'text.primaryMain' }}>
          <Typography
            component="span"
            variant="subtitle"
            sx={{
              textDecoration: 'line-through'
            }}
          >
            N
          </Typography>
          &nbsp;
          {fShortenNumber(amount)}
        </Typography>
      </Grid>
    </Grid>
  );
}
